import React from 'react'
import styled from 'styled-components'

import { FaAt, FaGithub, FaLinkedin } from 'react-icons/fa'

import Layout from '../components/layout'

const ContactList = styled.ul`
  list-style-type: none;
`

const ContactItemLink = styled.a`
  text-decoration: none;
`

const Icon = styled.span`
  vertical-align: text-top;
`

const ContactItem = ({ href, content, icon }) => (
  <li>
    <ContactItemLink href={href}>
      <Icon>{icon}</Icon> {content}
    </ContactItemLink>
  </li>
)

export const ContactDetails = () => (
  <ContactList>
    <ContactItem
      href="mailto:javcasas@gmail.com"
      icon={<FaAt />}
      content="javcasas@gmail.com"
    />
    <ContactItem
      href="https://github.com/javcasas"
      icon={<FaGithub />}
      content="github.com/javcasas"
    />
    <ContactItem
      href="https://linkedin.com/in/javier-casas-velasco-88108651"
      icon={<FaLinkedin />}
      content="linkedin.com/in/javier-casas-velasco-88108651"
    />
  </ContactList>
)

const ContactPage = () => (
  <Layout
    metaDescription="Contact Javier Casas"
    metaKeywords="contact, javier, casas"
  >
    <h1>Contact details</h1>
    <ContactDetails />
  </Layout>
)

export default ContactPage
