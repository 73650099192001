import React from 'react'

import Layout from '../components/layout'
import { ContactDetails } from './contact'

const CvPage = () => (
  <Layout
    metaDescription="Javier Casas' CV and professional achievements"
    metaKeywords="javier, casas, cv"
  >
    <h1>CV</h1>
    <h2>Contact details</h2>
    <ContactDetails />

    <h2>Overview</h2>
    <ul>
      <li>
        Software consultant with a background on web development and embedded
        systems.
      </li>
      <li>
        Looking for a remote contract in <em>Finance</em> and/or{' '}
        <em>Fullstack Functional Programming</em>.
      </li>
      <li>
        Learner, ability to work on tight deadlines, low overhead, high
        throughput.
      </li>
    </ul>

    <h2>Main themes</h2>
    <h3>Fullstack</h3>
    <ul>
      <li>
        Worked for a startup that is based on Haskell + Purescript + React and
        uses microservices to deliver the best college admissions coaching
        available.
      </li>
      <li>
        Helped interconnect the backend and the frontend in a way few projects
        are able to construct. See details at:{' '}
        <a href="https://www.stackbuilders.com/tutorials/functional-full-stack/purescript-bridge/">
          www.stackbuilders.com/tutorials/functional-full-stack/purescript-bridge/
        </a>
      </li>
      <li>
        Worked on a startup based on Haskell + React Native that constructs a
        native app to improve the behaviour of children.
      </li>
      <li>
        Lead a project based on C# and React/TypeScript to deliver a real-time
        monitoring system for Latin-America shrimp farms.
      </li>
    </ul>

    <h3>Finance</h3>
    <ul>
      <li>
        Lead a project based on Haskell that delivers portfolio performance
        optimization based on stock option allocation.
      </li>
      <li>
        Lead a startup that uses Haskell, the Cardano Blockchain and Plutus
        Smart Contracts to connect employers and workers.{' '}
      </li>
      <li>
        Currently studying Trading and Exchanges: Market Microstructure for
        Practitioners
      </li>
    </ul>

    <h3>Functional programming</h3>
    <ul>
      <li>
        Lead a weekly advanced readings group that made a group of strong
        Haskell developers into type-level developers.
      </li>
      <li>
        Currently studying The Little Typer, Thinking with Types and The Little
        Prover.
      </li>
    </ul>

    <h3>DevOps</h3>
    <ul>
      <li>
        Constructed the main prototype for running a microservices platform
        locally using Docker, docker-compose, and scripts for preloading
        dockerised databases.
      </li>
      <li>
        Constructed CI/CD pipelines for several projects, based on CircleCI and
        GitlabCI.
      </li>
      <li>
        Constructed several Ansible configuration playbooks that construct the
        main servers from bare Debian AWS instances.
      </li>
    </ul>

    <h3>Remote Agile</h3>
    <ul>
      <li>
        Worked on projects both based on Scrum and Kanban, working with remote
        teams, clients and developers.
      </li>
      <li>Effective as Tech Lead and part-time Project Manager/Scrummaster.</li>
      <li>
        Ability to prioritise, schedule, refine and estimate stories, organise
        sprints and unblock peers.
      </li>
    </ul>

    <h2>Work experience by date</h2>

    <h3>2016-2019 - Software Developer at Stack Builders (Quito, Ecuador)</h3>
    <ul>
      <li>
        Lead and worked on 6 projects for different clients in education,
        industry and finance.
      </li>
      <li>
        Worked remotely with clients from USA, Australia and Latin America.
      </li>
      <li>
        Grew from Tier 1 to Tier 3 Developer, being now one of the three people
        with Tier 3 level.
      </li>
      <li>Developed training plans for junior associates.</li>
    </ul>

    <h3>2013-2015 - Product Developer at ApplianSys (Coventry, UK)</h3>
    <ul>
      <li>
        Developed new features & bug fixes for the main products of the company.
      </li>
      <li>Supported and help progress major opportunities and sales.</li>
      <li>Translated the product and user manuals to Spanish.</li>
      <li>
        Researched and implemented support for HTTPS caching, that gave GBP40K
        in sales in 2014, and more than GBP250K expected sales in 2015.
      </li>
    </ul>

    <h3>
      2012-2012 - Programming Internship at Proxima Systems (Valladolid, Spain)
    </h3>
    <ul>
      <li>Developed embedded applications for industrial ARM controllers</li>
    </ul>

    <h2>Education</h2>
    <h3>2001-2011 - Ingeniero Técnico en Informática de Sistemas</h3>
    <ul>
      <li>University of Valladolid, Spain</li>
    </ul>
  </Layout>
)

export default CvPage
